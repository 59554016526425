<template>
  <BPCard
    dataTestId="settlements-table"
    :headerConfig="headerConfig"
    :contractMode.sync="contractMode"
    hideTransfers
  >
    <v-row class="mr-2" dense>
      <PayPeriodSelector @category-changed="currentModuleSetter"/>
      <v-col cols="auto" class="mt-4">
        <Icon
        icon="mdi-plus"
        iconColor="secondary"
        dataTestId="settlement-new-batch-button"
        :small="false"
        tooltipColor="secondary"
        :tooltipText="$t('createExportBatch')"
        @icon-clicked="openExportBatchDialog()"
        :disabled="!userAssignedClaim(UserClaims.ExportManager)"/>
      </v-col>
      <v-spacer/>
      <v-col cols="auto" class="mt-4">
        <Icon
        icon="mdi-refresh"
        iconColor="secondary"
        dataTestId="settlement-refresh-button"
        :small="false"
        tooltipColor="secondary"
        :tooltipText="$t('refresh')"
        @icon-clicked="refreshModuleData()"/>
        <SettlementActionMenu
        :isByProducts="isByProducts"
        :isExporter="userAssignedClaim(UserClaims.ExportManager)"
        @export-button-pressed="exportButtonPressed"
        @open-ticket-pdf-print="openTicketPdfDialog()"
        @open-integrations-dialog="openIntegrationsDialog()"
        @open-rate-verification-dialog="openRateVerificationDialog()"
        />
      </v-col>
    </v-row>
    <v-row dense class="mt-n6">
      <v-col cols="12">
        <AccountsPayableModule v-if="!isByProducts && currentCategory === AccountingCategory.Payable"/>
        <BPAccountsPayableModule v-if="isByProducts && currentCategory === AccountingCategory.Payable"/>
        <AccountsReceivableModule v-if="!isByProducts && currentCategory === AccountingCategory.Receivable" />
        <BPAccountsReceivableModule v-if="isByProducts && currentCategory === AccountingCategory.Receivable" />
        <AccrualsModule v-if="!isByProducts && currentCategory === AccountingCategory.Accrual"/>
        <BPAccrualsModule v-if="isByProducts && currentCategory === AccountingCategory.Accrual"/>
      </v-col>
    </v-row>
    <Dialog
    :stateId="dialogId"
    @close="resetDialogs"
    >
      <SettlementTicketsPDFDialog
      v-if="ticketPDFDialog"
      @close="resetDialogs"/>
      <ExportBatchDialog v-if="exportBatchDialog"
      @close="resetDialogs"/>
      <IntegrationsDialog
      v-if="integrationsDialog"
      :dateConfiguration="dateConfiguration"/>
      <RateVerificationDialog
      v-if="rateVerificationDialog"
      @close="resetDialogs"
      @refresh-batch="refreshModuleAndClearStaleData()"/>
    </Dialog>
    <v-dialog v-model="exportDialog" width="400">
      <ConfirmDialog
      v-if="exportDialog"
      :title.sync="confirmationTitle"
      :body.sync="confirmationSubtitle"
      confirmText="Confirm"
      color="secondary"
      @cancel="resetDialogs"
      @confirm="exportPayPeriod"
      />
    </v-dialog>
  </BPCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SettlementKeys, SettlementSubKeys } from '@/components/settlements/SettlementKeys.js'
import { LocalStorageKeys } from '@/utils/constants.js'
import { utcToLocalDate } from '@/utils/DateFormatter.js'
import { PayPeriodStatus, ContractModes, AccountingCategory, UserClaims } from '@/utils/Enumerations.js'
import { userAssignedClaim } from '@/utils/ClaimUtility.js'

export default {
  name: 'Settlements',

  components: {
    PayPeriodSelector: () => import('@/components/settlements/PayPeriodSelector.vue'),
    AccountsPayableModule: () => import('@/components/settlements/accounts-payable-module/AccountsPayableModule.vue'),
    BPAccountsPayableModule: () => import('@/components/settlements/accounts-payable-module/BPAccountsPayableModule.vue'),
    AccountsReceivableModule: () => import('@/components/settlements/accounts-receivable-module/AccountsReceivableModule.vue'),
    BPAccountsReceivableModule: () => import('@/components/settlements/accounts-receivable-module/BPAccountsReceivableModule.vue'),
    AccrualsModule: () => import('@/components/settlements/accruals-module/AccrualsModule.vue'),
    BPAccrualsModule: () => import('@/components/settlements/accruals-module/BPAccrualsModule.vue'),
    SettlementActionMenu: () => import('@/components/settlements/SettlementActionMenu.vue'),
    SettlementTicketsPDFDialog: () => import('@/components/settlements/SettlementTicketsPDFDialog.vue'),
    IntegrationsDialog: () => import('@/components/settlements/business-integrations/IntegrationsDialog.vue'),
    Icon: () => import('@/components/helper/Icon.vue'),
    BPCard: () => import('@/components/core/BPCard.vue'),
    Dialog: () => import('@/components/Dialog.vue'),
    ConfirmDialog: () => import('@/components/helper/ConfirmDialog.vue'),
    ExportBatchDialog: () => import('@/components/settlements/export-batch-form/ExportBatchDialog.vue'),
    RateVerificationDialog: () => import('@/components/settlements/rate-verification/RateVerificationDialog.vue')
  },

  data: () => ({
    currentCategory: AccountingCategory.enums[0],
    dialogId: 'settlements-dialog',
    exportDialog: false,
    exportBatchDialog: false,
    correctionsDialog: false,
    integrationsDialog: false,
    readyForPaymentTickets: [],
    postedTickets: [],
    ticketPDFDialog: false,
    contractMode: null,
    rateVerificationDialog: false,
    AccountingCategory,
    UserClaims
  }),

  watch: {
    currentCategory (val) {
      this.currentModuleSetter(val)
    },

    currentSubModule: {
      handler (val) {
        if (val === undefined) {
          this.$nextTick(_ => {
            this.refreshModuleData()
          })
        }
      },
      deep: true
    },

    dateConfiguration: {
      handler (_, oldVal) {
        if (oldVal?.exportBatch === undefined) this.refreshModuleData(false)
      },
      deep: true
    },

    contractMode (cm) {
      localStorage.setItem(LocalStorageKeys.SETTLEMENTS_CONTRACT_MODE, cm.value)
      this.currentModuleSetter(this.currentCategory)
    }
  },
  created () {
    const savedContractMode = parseInt(localStorage.getItem(LocalStorageKeys.SETTLEMENTS_CONTRACT_MODE)) || undefined
    this.contractMode = (savedContractMode && ContractModes.find(cm => cm.value === savedContractMode)) ?? ContractModes[0]
  },

  beforeDestroy () {
    this.resetModules()
  },

  mounted () {
    if (!this.currentSubModule) {
      this.$nextTick(_ => {
        this.refreshModuleData()
      })
    }
  },

  computed: {
    ...mapGetters('settlements', ['moduleNames', 'dateConfiguration', 'currentModuleKey', 'currentSubModule']),
    ...mapGetters('user', ['userClaims']),
    subtitle () {
      const batch = this.dateConfiguration.exportBatch
      return this.dateConfiguration?.exportBatch?.exportedBy
        ? this.$t('batchExportedByNOnDate', { batch: batch.label, n: batch.exportedBy, date: utcToLocalDate(batch.exportDate, 'L - LT') })
        : this.$t('noExportBatchSelected')
    },

    isByProducts () {
      return this.contractMode?.value === 1
    },

    headerConfig () {
      switch (this.contractMode?.value) {
        case 0: return { title: this.$t('settlements'), subtitle: this.subtitle }
        case 1: return { title: this.$t('byProductSettlements'), subtitle: this.subtitle }
      }

      return null
    },

    confirmationTitle () {
      const { payPeriod } = this.dateConfiguration
      if (payPeriod) {
        return this.$t('exportPayPeriod')
      } else {
        return ''
      }
    },

    confirmationSubtitle () {
      const { payPeriod } = this.dateConfiguration
      if (!payPeriod) return ''

      return (this.readyForPaymentTickets === 0)
        ? this.$t('exportSelectedPayPeriodAndFinalize')
        : `${this.$t('exportSelectedPayPeriodAndFinalize')} \n\n Tickets Ready For Payment: ${this.readyForPaymentTickets}* \n Tickets Posted: ${this.postedTickets} \n\n *Exporting this pay period will roll the tickets ready for payment to the next pay period.`
    },

    correctionsButtonDisabled () {
      const { payPeriod } = this.dateConfiguration

      if (payPeriod === undefined || payPeriod === null) {
        return true
      }

      return payPeriod.corrections <= 0
    }
  },

  methods: {
    ...mapActions('settlements', ['refreshModuleData', 'setCurrentModule', 'resetModules', 'clearStaleSubModuleData']),
    ...mapActions('dialog', ['openOrUpdateDialog', 'closeDialogsAtOrAbove']),
    ...mapActions('pay-period', ['getPayPeriodWithId', 'exportPeriod', 'fetchPayPeriods']),
    ...mapActions('export-batch', ['fetchExportBatches']),
    userAssignedClaim,

    statusFromInt: (x) => PayPeriodStatus.fromInt(x),
    moduleSelected (moduleName) {
      this.currentModule = this.moduleNames.findIndex(mn => mn === moduleName)
    },

    resetDialogs (refresh = false) {
      this.exportDialog = false
      this.exportBatchDialog = false
      this.integrationsDialog = false
      this.ticketPDFDialog = false
      this.rateVerificationDialog = false
      this.readyForPaymentTickets = []
      this.postedTickets = []
      this.closeDialogsAtOrAbove(this.dialogId)

      if (refresh) {
        this.fetchExportBatches(this.dateConfiguration.payPeriod.payPeriodId)
        this.$nextTick(_ => {
          this.refreshModuleAndClearStaleData()
        })
      }
    },

    openExportBatchDialog () {
      this.exportBatchDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
    },

    openTicketPdfDialog () {
      this.ticketPDFDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '600' })
    },

    openIntegrationsDialog () {
      this.integrationsDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '80%' })
    },

    openRateVerificationDialog () {
      this.rateVerificationDialog = true
      this.openOrUpdateDialog({ id: this.dialogId, width: '60%' })
    },

    async exportButtonPressed () {
      const { payPeriod, fiscalYear } = this.dateConfiguration

      const res = await this.getPayPeriodWithId({
        payPeriodId: payPeriod.payPeriodId,
        fiscalYearId: fiscalYear.fiscalYearId
      })

      this.readyForPaymentTickets = res.readyForPayment
      this.postedTickets = res.posted
      this.exportDialog = true
    },

    async exportPayPeriod () {
      const confirmation = confirm('Are you sure you want to export the tickets for this pay period? There is no way to reverse this action.')
      if (confirmation) {
        const { payPeriod, fiscalYear } = this.dateConfiguration
        const requestObj = {
          payPeriod: JSON.parse(JSON.stringify(payPeriod))
        }
        await this.exportPeriod(requestObj)
        await this.fetchPayPeriods(fiscalYear.fiscalYearId)
      }

      this.exportDialog = false
      this.resetDialogs({ refresh: true })
    },

    currentModuleSetter (val) {
      switch (val) {
        case AccountingCategory.Payable:
          this.setCurrentModule({
            moduleKey: SettlementKeys.AccountsPayable,
            subModuleKey: this.isByProducts ? SettlementSubKeys.AP.ByproductSettlementsByPayee : SettlementSubKeys.AP.ProductionSummary
          })
          break
        case AccountingCategory.Receivable:
          this.setCurrentModule({
            moduleKey: SettlementKeys.AccountsReceivable,
            subModuleKey: this.isByProducts ? SettlementSubKeys.AR.BPReceivableRegister : SettlementSubKeys.AR.ReceivableRegister
          })
          break
        case AccountingCategory.Accrual:
          this.setCurrentModule({
            moduleKey: SettlementKeys.Accruals,
            subModuleKey: this.isByProducts ? SettlementSubKeys.AC.BPRegister : SettlementSubKeys.AC.Register
          })
          break
      }
      if (this.currentCategory !== val) this.currentCategory = val
    },

    async refreshModuleAndClearStaleData () {
      await this.refreshModuleData(false)
      this.clearStaleSubModuleData({ isByproduct: this.isByProducts, allData: true })
    }
  }
}
</script>
